






























































































import {SfList} from '@storefront-ui/vue'
import DsbNotification from '~/components/DsbNotification.vue'
import DsbOrderListItem from '~/modules/customer/components/Orders/DsbOrderListItem.vue'
import {PageCategory} from '~/types/PageCategory'
import {SavedCartHeader, SavedCartHeaderScreenreader} from '~/types/savedCarts/savedCartsCategories'
import PageFooter from '~/components/Page/PageFooter.vue'
import {ref, defineComponent} from "@nuxtjs/composition-api";

export default defineComponent({
  name: 'Accordion',
  components: {
    DsbOrderListItem,
    SfList,
    DsbNotification,
    PageFooter
  },
  props: {
    cart: {
      type: Object,
      required: true
    }
  },
  emits: ['confirmDeleteSavedCart', 'addSavedCartToCart'],
  setup(props, {emit}) {

    const isOpen = ref(false);

    const openAccordion = () => {
      isOpen.value = !isOpen.value
    }

    const emitAddSavedCartToCart = (cartId, cartName) => {
      emit('addSavedCartToCart', cartId, cartName)
    }

    const emitConfirmDeleteSavedCart = (name, cartId) => {
      emit('confirmDeleteSavedCart', name, cartId)
    }

    return {
      isOpen,
      openAccordion,
      PageCategory,
      SavedCartHeader,
      SavedCartHeaderScreenreader,
      emitAddSavedCartToCart,
      emitConfirmDeleteSavedCart
    }
  },
});
