



























import { PageCategory } from '~/types/PageCategory'
import ProductDetails from '~/components/ProductDetails.vue'

export default {
  name: 'DsbOrderListItem',
  components: {
    ProductDetails,
  },
  props: {
    sku: {
      required: true,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    text: {
      type: String,
    },
    imgSrc: {
      required: true,
      type: String,
    },
    details: {
      required: true,
      type: Array,
    },
  },
  setup () {
    return {
      PageCategory,
    }
  },
}
