





























































import { defineComponent } from '@vue/composition-api'
import { SfIcon, SfButton } from '@storefront-ui/vue'
import { dsbFocusTrap } from '~/utilities/directives/focus-trap/dsb-focus-trap-directive'

export default defineComponent({
  name: 'DsbNotification',
  directives: { dsbFocusTrap },
  components: {
    SfIcon,
    SfButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'info',
      validator (value: string) {
        return ['', 'info', 'success', 'warning', 'error'].includes(
          value,
        )
      },
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible: {
      handler: function (value) {
        if (value) {
          this.$nextTick(() => {
            // @ts-ignore
            this.$refs["close-button"]?.querySelector("button").focus()
          });
          // @ts-ignore
          document.addEventListener("keydown", this.keydownHandler);
        } else {
          // @ts-ignore
          document.removeEventListener("keydown", this.keydownHandler);
        }
      },
    },
  },
  computed: {
    icon () {
      switch (this.type) {
        case 'info':
          return 'dsb-notification__icon--info'
        case 'error':
          return 'dsb-notification__icon--error'
        case 'success':
          return 'dsb-notification__icon--success'
        case 'warning':
          return 'dsb-notification__icon--warning'
        default:
          return 'dsb-notification__icon--info'
      }
    },
    colorClass () {
      switch (this.type) {
        case 'info':
          return 'bg-sf-c-info-lighten border-sf-c-info'
        case 'error':
          return 'bg-sf-c-danger-lighten border-sf-c-danger'
        case 'success':
          return 'bg-sf-c-success-lighten border-sf-c-success'
        case 'warning':
          return 'bg-sf-c-warning-lighten border-sf-c-warning'
        default:
          return 'bg-sf-c-info-lighten border-sf-c-info'
      }
    },
  },
  methods: {
    actionHandler () {
      this.$emit('click:action')
    },
    closeHandler () {
      this.$emit('click:close')
    },
  },
})
