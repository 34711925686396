var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"dsb-product-details",class:{
    flex: !_vm.grid,
    'grid justify-between': _vm.grid,
    'sm:flex': _vm.grid === 'sm',
    'md:flex': _vm.grid === 'md',
    'lg:flex': _vm.grid === 'lg',
    'xl:flex': _vm.grid === 'xl',
    '2xl:flex': _vm.grid === '2xl',
    'flex-col space-y-sf-xs': _vm.wrap,
    'sm:space-y-0 md:flex-row md:space-x-sf-lg': _vm.wrap === 'sm',
    'md:space-y-0 md:flex-row md:space-x-sf-lg': _vm.wrap === 'md',
    'lg:space-y-0 lg:flex-row lg:space-x-sf-lg': _vm.wrap === 'lg',
    'xl:space-y-0 xl:flex-row xl:space-x-sf-lg': _vm.wrap === 'xl',
    '2xl:space-y-0 2xl:flex-row 2xl:space-x-sf-lg': _vm.wrap === '2xl',
    'space-x-sf-lg': !_vm.wrap && !_vm.grid,
  }},_vm._l((_vm.details),function(detail,type){return _c('li',{key:type,staticClass:"dsb-product-details__item",class:{
      'article-number': detail.type === _vm.ProductDetailType.ARTICLE_NUMBER,
      'delivery-status': detail.type === _vm.ProductDetailType.DELIVERY_STATUS,
      'ordered-quantity': detail.type === _vm.ProductDetailType.ORDERED_QUANTITY,
      'dsb-product-details__price': detail.type === _vm.ProductDetailType.PRICE,
      'dsb-product-details__price-total': detail.type === _vm.ProductDetailType.PRICE_TOTAL,
    }},[(detail.type == _vm.ProductDetailType.PRICE || detail.type == _vm.ProductDetailType.PRICE_TOTAL)?_c('span',{staticClass:"text-sm pb-sf-3xs"},[(Number(detail.text) > 0)?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t(((_vm.PageCategory.PRODUCT) + "." + (detail.type))))+"\n      ")]):_vm._e()]):_c('span',{staticClass:"text-sm pb-sf-3xs"},[_vm._v("\n      "+_vm._s(_vm.$t(((_vm.PageCategory.PRODUCT) + "." + (detail.type))))+"\n    ")]),_vm._v(" "),(detail.type === _vm.ProductDetailType.AVAILABILITY)?_c('span',[_c('span',{staticClass:"dsb-product-details__in-stock inline-block w-sf-xs h-sf-xs rounded-full",class:{
          'bg-sf-c-success': detail.text === _vm.ProductAvailability.AVAILABLE,
          'bg-sf-c-warning':
            detail.text === _vm.ProductAvailability.DELIVERY_FROM_WEEK,
          'bg-sf-c-danger': detail.text === _vm.ProductAvailability.NOT_AVAILABLE,
        }}),_vm._v("\n      "+_vm._s(_vm.$t(((_vm.PageCategory.PRODUCT) + "." + (detail.text)), {
          week: detail.options.week,
        }))+"\n    ")]):(detail.type === _vm.ProductDetailType.LICENCE)?_c('span',{staticClass:"dsb-product-details__licence-ends dsb-icon-left dsb-info-icon"},[_vm._v("\n      "+_vm._s(detail.text)+"\n    ")]):(detail.type === _vm.ProductDetailType.LICENCE_EXPIRED)?_c('span',{staticClass:"text-danger dsb-icon-left dsb-error-icon"},[_vm._v("\n      "+_vm._s(detail.text)+"\n    ")]):(detail.type === _vm.ProductDetailType.ARTICLE_NUMBER)?_c('span',{staticClass:"articleNumber searchResultProductNumber"},[_vm._v("\n      "+_vm._s(detail.text)+"\n    ")]):(detail.type === _vm.ProductDetailType.DELIVERY_STATUS)?_c('span',[_vm._v("\n      "+_vm._s(_vm.$t(_vm.PageCategory.ORDERS + "." + detail.text))+"\n    ")]):(
        detail.type === _vm.ProductDetailType.PRICE_INCL_ZERO
        || detail.type === _vm.ProductDetailType.PRICE_TOTAL_INCL_ZERO
        || detail.type === _vm.ProductDetailType.PRICE
        || detail.type === _vm.ProductDetailType.PRICE_TOTAL)?_c('span',[(
        (detail.type == _vm.ProductDetailType.PRICE && Number(detail.text) > 0)
        || (detail.type == _vm.ProductDetailType.PRICE_TOTAL && Number(detail.text) > 0)
        || detail.type === _vm.ProductDetailType.PRICE_INCL_ZERO
        || detail.type === _vm.ProductDetailType.PRICE_TOTAL_INCL_ZERO)?_c('span',[_vm._v("\n        "+_vm._s(_vm.$n(detail.text, 'currency', 'EUR'))+"\n      ")]):_vm._e()]):_c('span',[_vm._v(_vm._s(detail.text))])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }