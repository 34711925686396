








import {defineComponent, onMounted} from '@nuxtjs/composition-api'
import { usePage, useFocusHelper } from '~/composables'
import PageHeader from '~/components/Page/PageHeader.vue'
import PageFooter from '~/components/Page/PageFooter.vue'
import DsbSavedCartsList from '~/modules/savedCarts/components/SavedCartsList.vue'
import { getInstance } from '~/helpers/hooks/getInstance'
import { PageCategory } from '~/types/PageCategory'
import { General } from '~/types/Page'

export default defineComponent({
  name: 'SavedCarts',
  middleware: ['is-authenticated', 'has-accepted-licenses', 'has-dummy-email', 'is-internal-valid'],
  components: { DsbSavedCartsList, PageHeader, PageFooter },
  // @ts-ignore
  head() {
    return {
      title: this.$t(`${PageCategory.SAVED_CARTS}.${General.TITLE}`)
    }
  },
  setup () {
    const vm = getInstance();
    const { pageTitle } = usePage()
    pageTitle.value = vm.proxy.$i18n.t(`${PageCategory.SAVED_CARTS}.${General.TITLE}`).toString()

    const {focusOnFirstInteractiveElementInMain} = useFocusHelper()

    onMounted(async () => {
      focusOnFirstInteractiveElementInMain()
    })

    return {
      pageTitle,
    }
  }
})
