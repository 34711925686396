











































import {SfList, SfLoader} from '@storefront-ui/vue'
import DsbNotification from '~/components/DsbNotification.vue'
import DsbOrderListItem from '~/modules/customer/components/Orders/DsbOrderListItem.vue'
import {PageCategory} from '~/types/PageCategory'
import {SavedCartHeader, SavedCartHeaderScreenreader} from '~/types/savedCarts/savedCartsCategories'
import {useSavedCarts} from "~/modules/savedCarts/composables/useSavedCarts";
import {defineComponent, useFetch, computed, useRoute} from "@nuxtjs/composition-api";
import PageFooter from '~/components/Page/PageFooter.vue'
import Accordion from "~/modules/savedCarts/components/Accordion.vue";
import {defaultPageSize} from "~/modules/catalog/category/composables/useFacet/perPageOptions";

export default defineComponent({
  name: 'DsbSavedCartsList',
  components: {
    DsbOrderListItem,
    SfList,
    DsbNotification,
    PageFooter,
    Accordion,
    SfLoader
  },
  setup() {
    const {
      fetchSavedCarts,
      paginatedSavedCarts,
      isVisibleNotification,
      notificationTitle,
      notificationMessage,
      notificationType,
      closeNotification,
      confirmDeleteSavedCart,
      addSavedCartToCart,
      loading,
      pagination
    } = useSavedCarts();

    const route = useRoute();
    const {
      query: { page, itemsPerPage },
    } = route.value;

    useFetch(async () => {
      await fetchSavedCarts({
        pageSize: itemsPerPage ? Number.parseInt(itemsPerPage.toString(), 10) : defaultPageSize,
        currentPage: page ? Number.parseInt(page.toString(), 10) : 1,
      });
    })

    return {
      PageCategory,
      SavedCartHeader,
      SavedCartHeaderScreenreader,
      paginatedSavedCarts,
      isVisibleNotification,
      notificationTitle,
      notificationMessage,
      notificationType,
      closeNotification,
      confirmDeleteSavedCart,
      addSavedCartToCart,
      loading,
      pagination: computed(() => pagination.value)
    }
  }
})
