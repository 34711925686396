import { focusTrap } from '@storefront-ui/vue/src/utilities/directives/focus-trap/focus-trap-directive.js'

export const dsbFocusTrap = {
  bind (el, binding) {
    if (typeof binding.value === 'undefined' || binding.value) {
      focusTrap.bind(el)
    }
  },
  componentUpdated (el, binding) {
    if (typeof binding.value === 'undefined' || binding.value) {
      focusTrap.componentUpdated(el)
    }
  },
  unbind (el, binding) {
    if (typeof binding.value === 'undefined' || binding.value) {
      //if (el._lastFocusedElement) el._lastFocusedElement.focus();
      document.removeEventListener("keydown", el._keyHandler);
    }
  },
}
