








































































import {
  SfIcon, SfSelect, SfLink,
} from '@storefront-ui/vue'
import DsbPagination from '~/components/DsbPagination.vue'
import { AgnosticPagination } from '@vue-storefront/core'
import {PropType, useRouter, useRoute, defineComponent} from '@nuxtjs/composition-api'
import { PageCategory } from "~/types/PageCategory";
import { ListFooter } from "~/types/ListCategories";

export default defineComponent({
  name: 'PageFooter',
  components: { DsbPagination, SfIcon, SfSelect, SfLink },
  props: {
    pagination: {
      type: Object as PropType<AgnosticPagination>,
      required: true
    },
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    function updateRoute(page) {
      router.replace({...route, query: {itemsPerPage:page}})
    }

    return {
      updateRoute,
      PageCategory,
      ListFooter,
    }
  }
})
