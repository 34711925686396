













































































































import { PageCategory } from "~/types/PageCategory";
import { ProductDetailType } from "~/types/ProductData";
import { ProductAvailability, ProductGeneral } from "~/types/ProductCategories";

export default {
  name: "ProductDetails",
  props: {
    details: {
      required: true,
      type: Array,
    },
    wrap: {
      default: "",
      type: String,
    },
    grid: {
      default: "",
      type: String,
    },
  },
  setup() {
    return {
      PageCategory,
      ProductDetailType,
      ProductGeneral,
      ProductAvailability,
    };
  },
};
